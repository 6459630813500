import {group} from "@angular/animations";
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTabGroup} from "@angular/material/tabs";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {GeoJSONSource, LngLatLike, MapGeoJSONFeature, MapMouseEvent} from "maplibre-gl";
import {take} from "rxjs";
import {BackButtonComponent} from "../../components/back-button/back-button.component";
import {
  ConfirmDialogComponent,
  ConfirmDialogData
} from "../../components/dialogs/confirm-dialog/confirm-dialog.component";
import {PathMetadataFormComponent} from "../../components/path-metadata-form/path-metadata-form.component";
import {ReorderableListItem} from "../../components/reorderable-item-list/ReorderableListItem.interface";
import {
  ReorderableListItemClickEvent
} from "../../components/reorderable-item-list/ReorderableListItemClickEvent.interface";
import {
  ReorderableListItemMovedEvent
} from "../../components/reorderable-item-list/ReorderableListItemMovedEvent.interface";
import {
  ReorderableListItemRemovedEvent
} from "../../components/reorderable-item-list/ReorderableListItemRemovedEvent.interface";
import {ItineroMapDefaultAssets} from "../../config/map";
import {SystemCityDto} from "../../dtos/local/SystemCity.dto";
import {SystemPathDto} from "../../dtos/local/SystemPath.dto";
import {SystemPoiDto} from "../../dtos/local/SystemPoi.dto";
import {WaypointDto, WaypointDtoForm} from "../../dtos/local/Waypoint.dto";
import {WaypointPayload} from "../../dtos/local/WaypointPayload.interface";
import {MapboxGeocodeFeature} from "../../dtos/remote/mapbox/MapboxGeocodeFeature.interface";
import {CitiesService} from "../../services/api/cities.service";
import {PathsService} from "../../services/api/paths.service";
import {PoiService} from "../../services/api/poi.service";
import {GroupPathDetailPageService} from "../../services/group-path-detail-page.service";
import {MapboxAPIService} from "../../services/mapbox-api.service";
import {UserMetadataService} from "../../services/user-metadata.service";
import {ItineroMap} from "../../utility/maps/ItineroMap";
import * as geoutils from "../../utility/maps/lib/GeoUtils";
import * as turf from '@turf/turf';
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-group-path-detail-page',
  templateUrl: './group-path-detail-page.component.html',
  styleUrls: ['./group-path-detail-page.component.scss']
})
export class GroupPathDetailPageComponent implements OnInit {

  @ViewChild('metadata') pathMetadata!: PathMetadataFormComponent;

  mainLanguageControl = new FormControl();

  map!: ItineroMap;

  titleParams = {
    cityName: '',
    pathName: ''
  };

  path?: SystemPathDto;
  city!: SystemCityDto;

  waypoints: {
    list: ReorderableListItem<WaypointDto>[],
    form: FormGroup,
    selection: FormGroup<WaypointDtoForm> | undefined,
    selectedDto: WaypointDto | undefined,
  } = {
    list: [],
    form: new FormGroup({}),
    selection: undefined,
    selectedDto: undefined
  };

  onlyMap = false;

  backOverride = this.layoutBack.bind(this);

  get canSave() {
    const valid = this.stateValidity;
    const waypoints = this.waypoints.form.dirty;
    const metadata = this.gdps.detailsForm?.dirty ?? false;
    return valid && (waypoints || metadata);
  }

  get stateValidity(): boolean {
    const waypointsValid = this.waypointsValidity;
    const detailsValid = this.metadataValidity;
    return waypointsValid && detailsValid;
  }

  get waypointsValidity(): boolean {
    return this.waypoints.form.valid && this.waypoints.list.length >= 2;
  }

  get metadataValidity(): boolean {
    return this.gdps.detailsForm?.valid ?? false;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snack: MatSnackBar,
    private poisvc: PoiService,
    private pathsvc: PathsService,
    private citysvc: CitiesService,
    private mapboxapi: MapboxAPIService,
    public gdps: GroupPathDetailPageService,
    private dialog: MatDialog,
    private umd: UserMetadataService,
    private tx: TranslocoService
  ) {
    (window as any).waypoints = this.waypoints;
    (window as any).component = this;
  }

  async layoutBack(backButton: BackButtonComponent) {
    if (this.waypoints.selection) {
      this.map.clearShadowMarkers();
      this.map.clearCircles();
      this.exitWaypoint();
    } else {
      if (this.waypoints.form.dirty || (this.pathMetadata && this.pathMetadata.form?.dirty)) {
        const ref = this.dialog.open(ConfirmDialogComponent, { data: { message: 'messages.confirmLoseChanges' }});
        ref.afterClosed().subscribe(async (res) => {
          if (!res) {
            return;
          }

          await backButton.defaultBackBehavior();
        });
      } else {
        await backButton.defaultBackBehavior();
      }
    }
  }

  async ngOnInit() {
    this.mainLanguageControl.setValue(this.umd.dataLanguage);
    this.route.paramMap.pipe(take(1)).subscribe(async params => {
      this.map = new ItineroMap('map');
      await this.initPathData(params);
      await this.initCityData(params);
      await this.initWaypointsLayer();
      await this.initPOILayer();

      if (this._initialBoundingBox) {
        this.map.api.fitBounds(this._initialBoundingBox as any, { padding: 100, zoom: 11 });
      }

      this._waypointsOrder = this.waypoints.list.map(wp => wp.value.id);

      this.mainLanguageControl.valueChanges.subscribe(async val => {
        if (this.waypoints.selection) {
          this.map.clearShadowMarkers();
          this.map.clearCircles();
          this.exitWaypoint();
        }

        const lc = val as string;
        await this.initPathData(params);
      });

      this._initializing = false;
    });
  }

  async handleWaypointClicked($event: ReorderableListItemClickEvent<WaypointDto>) {
    this.editWaypoint($event.item.value.id);
  }

  editWaypoint(wpId: number) {
    const rli = this.waypoints.list.find(rli => rli.value.id === wpId);
    if (!rli) {
      console.warn("Cannot find dto with that id");
      return;
    }

    const dto = rli.value;
    this.waypoints.selection = this.waypoints.form.controls[wpId.toString()] as FormGroup;
    this.waypoints.selectedDto = dto;
  }

  async handleWaypointRemoved($event: ReorderableListItemRemovedEvent<WaypointDto>) {
    // component has removed a waypoint from the list. update layers?
    this.waypoints.form.removeControl($event.item.value.id.toString());
    this.waypoints.form.markAsDirty();
    this._waypointsOrder = this.waypoints.list.map(wp => wp.value.id);
    await this.updateWaypointsLayer();
  }

  async handleWaypointMoved($event: ReorderableListItemMovedEvent<WaypointDto>) {
    // component has moved waypoints in the list. update layers?
    // console.log("waypoints reordered", $event);
    // console.log("current order:", this._waypointsOrder);
    this._waypointsOrder = this.waypoints.list.map(wp => wp.value.id);
    this.waypoints.form.markAsDirty();
    // console.log("new order:", this._waypointsOrder);
    await this.updateWaypointsLayer();
  }

  async createWaypoint() {
    this.onlyMap = true;

    // wait for the promise to resolve, or wait for the user to select an option from geocoding
    const point = await new Promise<turf.Feature<turf.Point> | MapboxGeocodeFeature | null>(
      async (res, rej) => {
        this._createWaypointResolver = res;
        const data = await this.map.selectPoint();
        res(data);
        return data;
      }
    );

    this.onlyMap = false;

    if (!point) {
      return;
    }

    // console.log('typeof point', typeof point);
    // console.log('point instanceof turf.point', point instanceof turf.point);
    // console.log('point', point);

    const dto = this.emptyWaypointDto();
    dto.coordinates  = point.geometry.coordinates as number[];
    if (point.hasOwnProperty('text')) {
      dto.name = (point as MapboxGeocodeFeature).text;
    }
    const wp = this.addWaypoint(dto);
    wp.group.controls.audio.setValue(null);
    wp.group.controls.video.setValue(null);
    wp.group.controls.images.setValue(null);
    this.editWaypoint(wp.dto.id);
  }

  addWaypoint(dto = this.emptyWaypointDto()) {
    const group = this.waypointFormGroup();
    dto.id = this._currentNewWaypointId--;
    group.setValue(dto as WaypointDto & { relatedPoiId: number | null });
    this.waypoints.form.addControl(dto.id.toString(), group);
    this.waypoints.form.markAsDirty();
    this.updateWaypointsList();
    return {dto: dto, group: group};
  }

  exitWaypoint($event?: Partial<WaypointDto>) {
    if (!this.path || !this.waypoints.selection) {
      return;
    }

    // this.path.pois.splice(this.waypoints.selection.index, 1, $event as WaypointDto);
    this.waypoints.selection = undefined;
    this.waypoints.selectedDto = undefined;
    this.updateWaypointsList();
    this.updateWaypointsLayer();
  }

  waypointsChanged($event: any) {
    this.updateWaypointsLayer();
  }

  async savePath(): Promise<any> {
    // combine form data into single payload
    const payload = this.pathMetadata.getSavePayload();
    payload.pathPois = this.getWaypointsSavePayload();

    await this.pathsvc.updatePath(this.path!.id, payload);
    location.reload();

    this.snack.open(this.tx.translate('generic.entity.saved'), 'OK', { duration: 1500 });
  }

  async deletePath() {
    try {
      await this.pathsvc.delete(this.path!.id);
      await this.router.navigate(['..'], {relativeTo: this.route});
    } catch (err) {
      console.log('error during delete', err);
    }
  }

  async solveCreateWaypointPromiseFromGeocodingSelection($event: MapboxGeocodeFeature) {
    if (!this._createWaypointResolver) {
      return;
    }

    this.map.deactivateCurrentMode();
    this._createWaypointResolver($event);
  }

  private async initPathData(params: ParamMap) {
    const param = params.get('pathId');
    const id = Number(param);
    if (isNaN(id) || id === undefined || id === null) {
      console.warn("No pathId param was specified.");
      return;
    }

    const path = await this.pathsvc.getPathById(id, this.mainLanguageControl.value);
    this.path = path;
    path.pois.forEach(waypoint => {
      const group = this.waypointFormGroup(waypoint);
      this.waypoints.form.setControl(waypoint.id.toString(), group);
    });

    this._waypointsOrder = path.pois.map(wp => wp.id);

    this.updateWaypointsList();
    this.titleParams.pathName = path.name;
  }

  private async initCityData(params: ParamMap) {
    const param = params.get('cityId');
    const cityId = Number(param);
    if (isNaN(cityId) || cityId === undefined || cityId === null) {
      console.warn("No cityId param was specified.");
      return;
    }

    const cityData = await this.citysvc.getCity(cityId);
    this.city = cityData;

    this.titleParams.cityName = cityData.name;
    const center = geoutils.NumberArrayAsCoordinates(cityData.coordinates);
    await this.citysvc.setWorkingCity(cityId);
    if (!this._initialBoundingBox) {
      this._initialBoundingBox = turf.bbox(turf.point(center));
    }
    console.log('loaded city', cityData, center);
  }

  private async initWaypointsLayer() {
    await this.updateWaypointsLayer();
  }

  private async initPOILayer() {
    return new Promise((res, rej) => {
      this.map.whenReady(async () => {
        this._cityPois = await this.poisvc.getPoisByCity(this.city.id);
        const poiFeatures = turf.featureCollection(
          this._cityPois
            // exclude pois that are already added as waypoints (via relatedPoiId)
            .filter(poi => this.path!.pois.findIndex(wp => wp.relatedPoiId === poi.id) < 0)
            .map(poi => turf.point(poi.coordinates, poi))
        );

        this.map.api.addSource('city-pois-source', { type: 'geojson', data: poiFeatures });
        this.map.api.addLayer({
          id: 'city-pois-layer',
          source: 'city-pois-source' as any,
          type: "symbol",
          layout: {
            "icon-image": ItineroMapDefaultAssets.redMarker.id,
            "icon-size": 1,
          }
        });

        this.map.api.on('click', 'city-pois-layer', (ev) => {
          if (!ev.features) {
            return;
          }

          this.handleCityPoiClicked(ev.features[0], ev);
        });

        res(null);
      });
    });
  }

  private async updatePOILayer() {
    const poiSource = this.map.api.getSource('city-pois-source');
    if (!poiSource) {
      return;
    }
    const poiFeatures = turf.featureCollection(
      this._cityPois
        // exclude pois that are already added as waypoints (via relatedPoiId)
        .filter(poi => {
          const dto = this.path!.pois.find(p => p.id === poi.id);
          if (!dto) {
            return true;
          }

          return dto.relatedPoiId !== poi.id;
        })
        .filter((poi: any) => !poi.addedAsWaypoint)
        .map(poi => turf.point(poi.coordinates, poi))
    );

    const source = poiSource as GeoJSONSource;
    source.setData(poiFeatures);
  }

  private async updateWaypointsLayer() {
    // render markers
    this.map.clearMarkers();
    const waypoints = this.waypoints.list
      .map(wp => wp.value)
      .filter(wp => wp.coordinates.length > 0)
    ;
    waypoints.forEach(wp => {
      if (wp.coordinates.length === 2) {
        this.map.addMarker(turf.point(wp.coordinates));
      }
    });

    // render linestring
    this.map.clearLines();
    const points = waypoints.map(wp => turf.point(wp.coordinates));
    let bbox;
    if (waypoints.length > 0) {
      if (waypoints.length > 1) {
        // https://api.mapbox.com/directions/v5/walking/{coordinates}
        const geo = await this.mapboxapi.getDirections(points);

        console.log('mapbox geo api:', geo);
        this.map.addLine(geo.routes[0].geometry);

        bbox = turf.bbox(geo.routes[0].geometry);
      } else {
        bbox = turf.bbox(turf.featureCollection(points));
      }
    }

    // fit bounds
    if (bbox) {
      if (this._initializing) {
        this._initialBoundingBox = bbox;
      } else {
        this.map.api.fitBounds(bbox as any, { padding: 100, maxZoom: 14 });
      }
    }
  }

  private emptyWaypointDto(): WaypointDto {
    return {
      areaRadiusMeters: 0,
      audio: '',
      coordinates: [],
      description: '',
      id: 0,
      images: [],
      name: '',
      pathId: 0,
      video: '',
      relatedPoiId: null
    };
  }

  private waypointFromPOI(poi: SystemPoiDto): WaypointDto {
    const wp = this.emptyWaypointDto();
    wp.coordinates = poi.coordinates;
    wp.name = poi.name;
    wp.description = poi.description;
    wp.video = poi.video;
    wp.audio = poi.audio;
    if ((poi.images || []).length === 0) {
      wp.images = [poi.thumbnail];
    } else {
      wp.images = poi.images ?? [];
    }
    return wp;
  }

  private waypointFormGroup(waypoint?: WaypointDto) {
    return new FormGroup<WaypointDtoForm>({
      id: new FormControl<null | number>(waypoint?.id ?? null, [Validators.required]),
      pathId: new FormControl<null | number>(waypoint?.pathId ?? null, [Validators.required]),
      coordinates: new FormControl<null | number[]>({value: waypoint?.coordinates ?? null, disabled: true}, [Validators.required]),
      name: new FormControl<null | string>(waypoint?.name ?? null, [Validators.required]),
      description: new FormControl<null | string>(waypoint?.description ?? null, [Validators.required]),
      areaRadiusMeters: new FormControl<null | number>(waypoint?.areaRadiusMeters ?? null, [Validators.required]),
      images: new FormControl<null | (string | File)[]>(waypoint?.images ?? null),
      video: new FormControl<null | string | File>(waypoint?.video ?? null),
      audio: new FormControl<null | string | File>(waypoint?.audio ?? null),
      relatedPoiId: new FormControl<null | number>(waypoint?.relatedPoiId ?? null),
    });
  }

  private updateWaypointsList() {
    const waypointForms = Object.values<FormGroup<WaypointDtoForm>>(
      this.waypoints.form.controls as any
    );

    const waypoints = Object.values(waypointForms)
      .map(wpfg => ({
        value: wpfg.getRawValue() as any,
        removable: true,
        label: wpfg.value.name,
        invalid: wpfg.invalid
      } as ReorderableListItem<WaypointDto>))
    ;

    if (this._waypointsOrder.length > 0) {
      const idIndexMap = new Map();
      this._waypointsOrder.forEach((id, index) => {
        idIndexMap.set(id, index);
      });

      // Sort the waypoints array based on the order array
      waypoints.sort((a, b) => {
        const orderA = idIndexMap.get(a.value.id);
        const orderB = idIndexMap.get(b.value.id);

        // If either id is not found in the order array, place it at the end
        // if (orderA === undefined) return 1;
        // if (orderB === undefined) return -1;

        // Compare the order values for sorting
        return orderA - orderB;
      });
    }

    this.waypoints.list = waypoints;
  }

  private getWaypointsSavePayload(): WaypointPayload[] {
    // Create a map for quick lookup of index positions
    const orderMap = new Map(this._waypointsOrder.map((id, index) => [id, index]));
    return Object.values(this.waypoints.form.controls)
      .map(wpc => {
      const wp = (wpc as any as FormGroup<WaypointDtoForm>).getRawValue();
      const payload: WaypointPayload = {
        coordinates: wp.coordinates!.toString(),
        name: wp.name!,
        description: wp.description!,
        areaRadiusMeters: wp.areaRadiusMeters!
      };

      if (wp.id) {
        payload.pathPoiId = wp.id;
      }

      if (wp.video) {
        payload.video =  wp.video;
      }

      if (wp.audio) {
        payload.audio = wp.audio;
      }

      if (wp.images) {
        payload.images = wp.images;
      }

      return payload;
    })
      .sort((a, b) => (orderMap.get(a.pathPoiId ?? -1) ?? Infinity) - (orderMap.get(b.pathPoiId ?? -1) ?? Infinity))
    ;
  }

  private handleCityPoiClicked(feature: MapGeoJSONFeature, ev: MapMouseEvent & {
    features?: MapGeoJSONFeature[]
  } & Object) {
    const poiFeature = feature.properties as SystemPoiDto;
    const poi = this._cityPois.find(p => p.id === poiFeature.id);
    if (!poi) {
      console.warn("cannot find poi with that id");
      return;
    }

    // fixme: popups broken not sure how to fix rn
    // const popup = new Popup()
    //   .setLngLat(NumberArrayAsCoordinates((feature.geometry as turf.Point).coordinates))
    //   .setHTML(dto.name)
    //   .addTo(this.map.api)
    // ;

    const dialogData: ConfirmDialogData = {
      message: "generic.actions.createWaypointFromPOI",
      messageParameters: {
        poiName: poi.name
      }
    };

    const ref = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData
    });

    ref.afterClosed().subscribe(async (res) => {
      if (!res) {
        return;
      }

      console.log("creating", poi, "as waypoint");
      const wp = this.waypointFromPOI(poi);
      this.addWaypoint(wp);
      this.editWaypoint(wp.id);
      (poi as any).addedAsWaypoint = true;
      wp.relatedPoiId = poi.id;
      await this.updatePOILayer();
    });
  }

  private _currentNewWaypointId = -1;
  private _createWaypointResolver?: (point: turf.Feature<turf.Point> | MapboxGeocodeFeature | null) => void;

  private _initialBoundingBox?: turf.BBox;
  private _initializing = true;

  private _cityPois: SystemPoiDto[] = [];
  private _waypointsOrder: number[] = [];
  protected readonly group = group;
}
